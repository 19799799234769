import { LazyExoticComponent, lazy } from 'react'
import ROUTES_NAME from './routesNames'

const HomePage = lazy(async () => ({ default: (await import('../../pages/HomePage')).HomePage }))
const Step1 = lazy(async () => ({ default: (await import('../../pages/Step1')).Step1 }))
const Step2 = lazy(async () => ({ default: (await import('../../pages/Step2')).Step2 }))
const Step3 = lazy(async () => ({ default: (await import('../../pages/Step3')).Step3 }))
const Dashboard = lazy(async () => ({ default: (await import('../../pages/Dashboard')).Dashboard }))
const Login = lazy(async () => ({ default: (await import('../../pages/Login')).Login }))
const Settings = lazy(async () => ({ default: (await import('../../pages/Settings')).Settings }))
const VerifyEmail = lazy(async () => ({
	default: (await import('../../pages/VerifyEmail')).VerifyEmail
}))
const VerifyUser = lazy(async () => ({
	default: (await import('../../pages/VerifyUser')).VerifyUser
}))
const NoFound = lazy(async () => ({ default: (await import('../../pages/NoFound')).NoFound }))
const UnderMaintenance = lazy(async () => ({
	default: (await import('../../pages/UnderMaintenance')).UnderMaintenance
}))
const RecoverPassword = lazy(async () => ({
	default: (await import('../../pages/RecoverPassword')).RecoverPassword
}))
const PasswordReset = lazy(async () => ({
	default: (await import('../../pages/PasswordReset')).PasswordReset
}))
const DashboardAddLicence = lazy(async () => ({
	default: (await import('../../pages/DashboardAddLicence')).DashboardAddLicence
}))
const PaymentStep = lazy(async () => ({
	default: (await import('../../pages/PaymentStep')).PaymentStep
}))
const DashboardPayment = lazy(async () => ({
	default: (await import('../../pages/DashboardPayment')).DashboardPayment
}))
const DashboardCreateChild = lazy(async () => ({
	default: (await import('../../pages/DashboardCreateChild')).DashboardCreateChild
}))
const AdminRegistration = lazy(async () => ({
	default: (await import('../../pages/AdminRegistration')).AdminRegistration
}))
const ParentRegistration = lazy(async () => ({
	default: (await import('../../pages/ParentRegistration')).ParentRegistration
}))
const AddNewPolicy = lazy(async () => ({
	default: (await import('../../pages/AddNewPolicy')).AddNewPolicy
}))
const EditPolicy = lazy(async () => ({
	default: (await import('../../pages/EditPolicy')).EditPolicy
}))
const DashboardAddParentManually = lazy(async () => ({
	default: (await import('../../pages/DashboardAddParent')).DashboardAddParent
}))
const GetConnected = lazy(async () => ({
	default: (await import('../../pages/GetConnected')).GetConnected
}))
const DashboardAddChildManually = lazy(async () => ({
	default: (await import('../../pages/AdminDashboardCreateChild')).AdminDashboardCreateChild
}))
const DashboardAddAdmin = lazy(async () => ({
	default: (await import('../../pages/DashboardAddAdmin')).DashboardAddAdmin
}))
const AddNewArticle = lazy(async () => ({
	default: (await import('../../pages/AddNewArticle')).AddNewArticle
}))
const EditArticle = lazy(async () => ({
	default: (await import('../../pages/EditArticle')).EditArticle
}))

const privateRouteRoutes = [
	{
		path: ROUTES_NAME.STEP2,
		Element: Step2,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.STEP3,
		Element: Step3,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.DASHBOARD,
		Element: Dashboard,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.SETTINGS,
		Element: Settings,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_LICENCE,
		Element: DashboardAddLicence,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_PAYMENT,
		Element: DashboardPayment,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_CHILD,
		Element: DashboardCreateChild,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_PARENT,
		Element: DashboardAddParentManually,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_ADMIN,
		Element: DashboardAddAdmin,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_GET_CONNECTED,
		Element: GetConnected,
		privateRoute: true
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_CHILD,
		Element: DashboardAddChildManually,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.ADD_NEW_POLICY,
		Element: AddNewPolicy,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.EDIT_POLICY,
		Element: EditPolicy,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.ADD_NEW_ARTICLE,
		Element: AddNewArticle,
		privateRoute: true,
		superAdminOnly: true
	},
	{
		path: ROUTES_NAME.EDIT_ARTICLE,
		Element: EditArticle,
		privateRoute: true,
		superAdminOnly: true
	}
]

const publicRoutes = [
	{
		path: ROUTES_NAME.UNDER_MAINTENANCE,
		Element: UnderMaintenance
	},
	{
		path: '*',
		Element: NoFound
	},
	{
		path: ROUTES_NAME.PARENT_REGISTRATION,
		Element: ParentRegistration,
		checkFulfillment: true
	},
	{
		path: ROUTES_NAME.ADMIN_REGISTRATION,
		Element: AdminRegistration,
		checkFulfillment: true
	},
	{
		path: ROUTES_NAME.RECOVER_PASSWORD,
		Element: RecoverPassword
	},
	{
		path: ROUTES_NAME.VERIFY_EMAIL,
		Element: VerifyEmail
	},
	{
		path: ROUTES_NAME.VERIFY_USER,
		Element: VerifyUser
	},
	{
		path: ROUTES_NAME.PASSWORD_RESET,
		Element: PasswordReset
	},
	{
		path: ROUTES_NAME.STEP1,
		Element: Step1,
		checkFulfillment: true
	},
	{
		path: ROUTES_NAME.LOGIN,
		Element: Login
	},
	{
		path: ROUTES_NAME.PAYMENT_STEP,
		Element: PaymentStep
	},
	{
		path: ROUTES_NAME.HOME,
		Element: HomePage
	}
]

export const routes: Routes = [...publicRoutes, ...privateRouteRoutes]

type Routes = Array<Route>
type Route = {
	path: string
	Element: LazyExoticComponent<(props: any) => JSX.Element>
	privateRoute?: boolean
	superAdminOnly?: boolean
	checkFulfillment?: boolean
}
