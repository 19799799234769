import gql from 'graphql-tag'
import { FRAGMENT_USER } from 'src/shared/api/users'

export const UPDATE_PARENT_DASHBOARD = gql`
	mutation updateParentDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$id: ID!
	) {
		updateParentDashboard(email: $email, firstName: $firstName, lastName: $lastName, id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const UPDATE_ADMIN_DASHBOARD = gql`
	mutation updateAdminDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$id: ID!
		$schoolId: ID
	) {
		updateAdminDashboard(
			email: $email
			firstName: $firstName
			lastName: $lastName
			id: $id
			schoolId: $schoolId
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const DELETE_ADMIN_DASHBOARD = gql`
	mutation deleteAdminDashboard($id: ID!) {
		deleteAdminDashboard(id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EXTRA_EMAIL = gql`
	mutation createExtraEmails($emails: [LowerString]!) {
		createExtraEmails(emails: $emails) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EXTRA_EMAIL_DASHBOARD = gql`
	mutation createExtraEmailsDashboard($emails: [LowerString]!, $parentId: ID!) {
		createExtraEmailsDashboard(emails: $emails, parentId: $parentId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESEND_PARENT_INVITATION = gql`
	mutation resendParentInvitation($id: ID!) {
		resendParentInvitation(id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const RESEND_PARENTS_INVITATION = gql`
	mutation resendParentsInvitation($ids: [ID]) {
		resendParentsInvitation(ids: $ids) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const DELETE_PARENT_DASHBOARD = gql`
	mutation deleteParentDashboard($parentId: ID!) {
		deleteParentDashboard(parentId: $parentId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_CHILD_DASHBOARD = gql`
	mutation createChildDashboard($children: [ChildInput], $parentId: ID, $schoolId: ID) {
		createChildDashboard(children: $children, parentId: $parentId, schoolId: $schoolId) {
			ok
			errors {
				childErrors {
					fieldName
					errors
				}
			}
		}
	}
`

export const UPDATE_CHILD_DASHBOARD = gql`
	mutation updateChildDashboard($childId: ID, $name: LimitedString, $policyId: ID) {
		updateChildDashboard(childId: $childId, name: $name, policyId: $policyId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const DELETE_CHILD_DASHBOARD = gql`
	mutation deleteChildDashboard($childId: ID!) {
		deleteChildDashboard(childId: $childId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPDATE_POLICY = gql`
	mutation updateProfileDashboard(
		$id: ID!
		$name: LimitedString!
		$orderNumber: Int
		$isActive: Boolean
		$populateChanges: Boolean
		$recreationTimes: [RecreationTimeInput]
		$services: [ServiceInputType]
		$categories: [ID]
		$privacies: [ID]
		$whiteList: [String]
		$blackList: [String]
		$activeDomains: [ActiveDomainInputType]
	) {
		updateProfileDashboard(
			name: $name
			recreationTimes: $recreationTimes
			activeDomains: $activeDomains
			services: $services
			privacies: $privacies
			categories: $categories
			whiteList: $whiteList
			blackList: $blackList
			populateChanges: $populateChanges
			isActive: $isActive
			orderNumber: $orderNumber
			id: $id
		) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`
export const DELETE_POLICY = gql`
	mutation deleteProfileDashboard($id: ID!) {
		deleteProfileDashboard(id: $id) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`
export const CREATE_POLICY = gql`
	mutation createProfileDashboard(
		$name: LimitedString!
		$recreationTimes: [RecreationTimeInput]
		$services: [ServiceInputType]
		$privacies: [ID]
		$categories: [ID]
		$whiteList: [String]
		$blackList: [String]
		$activeDomains: [ActiveDomainInputType]
	) {
		createProfileDashboard(
			name: $name
			recreationTimes: $recreationTimes
			services: $services
			privacies: $privacies
			categories: $categories
			whiteList: $whiteList
			blackList: $blackList
			activeDomains: $activeDomains
		) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`

export const CREATE_PARENT_DASHBOARD = gql`
	mutation createParentDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
	) {
		createParentDashboard(email: $email, firstName: $firstName, lastName: $lastName) {
			ok
			user {
				id
			}
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_ADMIN_DASHBOARD = gql`
	mutation createAdminDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$schoolId: ID
	) {
		createAdminDashboard(
			email: $email
			firstName: $firstName
			lastName: $lastName
			schoolId: $schoolId
		) {
			ok
			user {
				id
			}
			errors {
				fieldName
				errors
			}
		}
	}
`
export const CREATE_EMAILS = gql`
	mutation createEmails(
		$emails: [LowerString]!
		$licencePeriod: Int
		$licenceQuantity: Int
		$schoolId: ID
	) {
		createEmails(
			emails: $emails
			licencePeriod: $licencePeriod
			licenceQuantity: $licenceQuantity
			schoolId: $schoolId
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const CREATE_ADMIN_EMAILS = gql`
	mutation createAdminEmails($emails: [LowerString]!, $schoolId: ID) {
		createAdminEmails(emails: $emails, schoolId: $schoolId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const UPLOAD_CSV_FILE = gql`
	mutation createParentCsvDashboard($csvFile: Upload!) {
		createParentCsvDashboard(csvFile: $csvFile) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESET_CHILL_TIME_MANUALLY = gql`
	mutation resetRecreationTimeManually($childId: ID!, $recreationTimeId: ID) {
		resetRecreationTimeManually(childId: $childId, recreationTimeId: $recreationTimeId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const REGISTER_ADMIN = gql`
	mutation registerAdmin(
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$schoolPolicy: Boolean!
		$termsOfUse: Boolean!
	) {
		registerAdmin(
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			schoolPolicy: $schoolPolicy
			termsOfUse: $termsOfUse
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const CREATE_PROGRESS_LICENCES = gql`
	mutation createInProgressLicences($amount: Int, $parentId: ID, $schoolId: ID) {
		createInProgressLicences(amount: $amount, parentId: $parentId, schoolId: $schoolId) {
			ok
			errors {
				fieldName
				errors
			}
			licences {
				code
				status
				licenceType
			}
		}
	}
`

export const CREATE_ARTICLE = gql`
	mutation createArticle(
		$isPublished: Boolean!
		$text: String!
		$title: LimitedString!
		$preview: Upload!
	) {
		createArticle(isPublished: $isPublished, text: $text, title: $title, preview: $preview) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const DELETE_ARTICLE = gql`
	mutation deleteArticle($id: ID!) {
		deleteArticle(id: $id) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`

export const UPDATE_ARTICLE = gql`
	mutation updateArticle(
		$id: ID!
		$isPublished: Boolean!
		$text: String!
		$title: LimitedString!
		$preview: Upload
	) {
		updateArticle(
			id: $id
			isPublished: $isPublished
			text: $text
			title: $title
			preview: $preview
		) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`
