import gql from 'graphql-tag'

export const GET_PARENTS = gql`
	query parents(
		$page: Int
		$perPage: Int
		$orderBy: UserOrderBy
		$sortBy: SortOrder
		$status: Status
		$search: String
		$school: ID
		$policy: ID
		$licenceStatus: DashboardQueryLicenceStatus
		$licenceType: DashboardQueryLicenceType
	) {
		parents(
			page: $page
			perPage: $perPage
			orderBy: $orderBy
			sortBy: $sortBy
			status: $status
			search: $search
			school: $school
			policy: $policy
			licenceStatus: $licenceStatus
			licenceType: $licenceType
		) {
			parents {
				id
				firstName
				lastName
				email
				status
				children {
					id
					name
				}
				isExtra
				hasExtra
				origin {
					email
					firstName
					lastName
				}
			}
			quantity
		}
	}
`
export const GET_PARENTS_QUANTITY = gql`
	query parents_quantity {
		parents {
			quantity
		}
	}
`

export const CHILDREN_SEARCH = gql`
	query childrenSearch($page: Int, $perPage: Int, $search: String) {
		childrenSearch(page: $page, perPage: $perPage, search: $search) {
			children {
				id
				name
				parent {
					firstName
					lastName
				}
				minLogDate
			}
			quantity
		}
	}
`

export const GET_MIN_LOG_DATE = gql`
	query minLogDate {
		minLogDate
	}
`

export const GET_ANALYTICS_ADMIN = gql`
	query analyticsAdmin(
		$fromData1: String!
		$toData1: String!
		$fromData2: String
		$toData2: String
		$childId: ID
	) {
		analyticsAdmin(
			fromData1: $fromData1
			toData1: $toData1
			fromData2: $fromData2
			toData2: $toData2
			childId: $childId
		) {
			queries {
				name
				queries
			}
			blockedReasons {
				name
				queries
			}
			querySeries {
				queries {
					name
					queries
				}
				times
			}
			devices
		}
	}
`

export const DASHBOARD_POLICIES = gql`
	query dashboardProfiles(
		$page: Int
		$perPage: Int
		$orderBy: ProfileOrderBy
		$sortBy: SortOrder
		$search: String
	) {
		dashboardProfiles(
			page: $page
			perPage: $perPage
			orderBy: $orderBy
			sortBy: $sortBy
			search: $search
		) {
			profiles {
				id
				name
				isBase
				isActive
				orderNumber
				code
			}
			quantity
		}
	}
`

export const GET_POLICIES_QUANTITY = gql`
	query dashboardProfilesQuantity {
		dashboardProfiles {
			quantity
		}
	}
`

export const GET_ADMINS = gql`
	query getAdmins(
		$page: Int
		$perPage: Int
		$orderBy: AdminOrderBy
		$sortBy: SortOrder
		$search: String
	) {
		admins(
			page: $page
			perPage: $perPage
			orderBy: $orderBy
			sortBy: $sortBy
			search: $search
		) {
			admins {
				id
				lastName
				firstName
				email
				isSuperuser
				school {
					id
					name
				}
			}
			quantity
		}
	}
`

export const GET_ADMINS_QUANTITY = gql`
	query getAdmins {
		admins {
			quantity
		}
	}
`

export const PARENT_EXPORT = gql`
	query parentSExport(
		$status: Status
		$school: ID
		$policy: ID
		$licenceStatus: DashboardExportQueryLicenceStatus
		$licenceType: DashboardExportQueryLicenceType
		$search: String
		$orderBy: UserOrderBy
		$sortBy: SortOrder
	) {
		parentsExport(
			status: $status
			school: $school
			policy: $policy
			licenceStatus: $licenceStatus
			licenceType: $licenceType
			search: $search
			orderBy: $orderBy
			sortBy: $sortBy
		)
	}
`

export const TASK_STATUS = gql`
	query taskStatus($id: String) {
		taskStatus(id: $id) {
			status
			url
		}
	}
`

export const GET_CHILD_DASHBOARD = gql`
	query childDashboard($id: ID) {
		childDashboard(id: $id) {
			id
			name
			policy {
				id
				name
			}
			licence {
				code
				expirationDate
				licenceType
				expirationDate
				profileCode
				status
				school {
					name
				}
			}
			recreationTimes {
				id
				isActive
				isCustom
				weekDay
				timeIntervals {
					start
					end
				}
			}
			canResetRecreationTime
			childAppUrl
		}
	}
`
export const GET_COUNTRIES = gql`
	query countries($page: Int, $perPage: Int, $sortBy: SortOrder, $search: String) {
		countries(page: $page, perPage: $perPage, sortBy: $sortBy, search: $search) {
			countries {
				id
				name
				icon
			}
			quantity
		}
	}
`
export const GET_DASHBOARD_CHILD_DEVICES = gql`
	query childDashboard($id: ID) {
		childDashboard(id: $id) {
			devices {
				id
				name
				model
				connectedDate
			}
		}
	}
`

export const REPORTING_EXPORT = gql`
	query reportingExport($fromDate: String!, $toDate: String!, $childId: ID) {
		reportingExport(fromDate: $fromDate, toDate: $toDate, childId: $childId)
	}
`

export const GET_BASE_POLICY = gql`
	query baseProfile {
		baseProfile {
			services {
				id
				name
				icon
				isActive
				hasRecreationTime
			}
			categories {
				id
				name
				isActive
				description
			}
			privacies {
				id
				name
				isActive
				description
			}
		}
	}
`

export const GET_POLICY = gql`
	query dashboardProfile($id: ID) {
		dashboardProfile(id: $id) {
			id
			name
			isActive
			orderNumber
			isBase
			recreationTimes {
				id
				weekDay
				isActive
				timeIntervals {
					start
					end
				}
			}
			services {
				id
				name
				icon
				isActive
				hasRecreationTime
			}
			categories {
				id
				name
				isActive
				description
			}
			privacies {
				id
				name
				description
				isActive
			}
			whiteList {
				name
			}
			blackList {
				name
			}
			activeDomains {
				domain
				activeTimes {
					id
					isActive
					weekDay
					timeIntervals {
						start
						end
					}
				}
			}
		}
	}
`

export const GET_PARENTS_CHILDREN = gql`
	query parent($id: ID, $sortBy: ParentTypeLicenceStatus) {
		parent(id: $id) {
			children(sortBy: $sortBy) {
				name
				licence {
					code
					status
					licenceType
					profileCode
				}
				childAppUrl
			}
		}
	}
`

export const GET_ARTICLES = gql`
	query articles(
		$page: Int
		$perPage: Int
		$orderBy: ArticleOrderBy
		$sortBy: SortOrder
		$isPublished: Boolean
		$search: String
	) {
		articles(
			page: $page
			perPage: $perPage
			orderBy: $orderBy
			sortBy: $sortBy
			isPublished: $isPublished
			search: $search
		) {
			articles {
				id
				title
				user {
					firstName
					lastName
				}
				isPublished
				publishedAt
			}
			quantity
		}
	}
`

export const GET_ARTICLE = gql`
	query article($id: ID!) {
		article(id: $id) {
			id
			title
			text
			preview {
				url
			}
			isPublished
			editedAt
		}
	}
`
