const ROUTES_NAME = {
	HOME: '/',
	LOGIN: '/login',
	DASHBOARD: '/dashboard',
	SETTINGS: '/settings',
	STEP1: '/step1',
	STEP2: '/step2',
	STEP3: '/step3',
	PAYMENT_STEP: '/payment-step',
	RECOVER_PASSWORD: '/recover-password',
	DASHBOARD_ADD_LICENCE: '/dashboard-add-licence',
	DASHBOARD_PAYMENT: '/dashboard-payment',
	DASHBOARD_CREATE_CHILD: '/dashboard-create-child',
	DASHBOARD_CREATE_PARENT: '/dashboard-create-parent',
	DASHBOARD_CREATE_ADMIN: '/dashboard-create-admin',
	DASHBOARD_GET_CONNECTED: '/dashboard-get-connected/:id',
	DASHBOARD_ADD_CHILD: '/dashboard-add-child/:parentId',
	ADMIN_REGISTRATION: '/admin-registration',
	PARENT_REGISTRATION: '/finish-parent-registration',
	UNDER_MAINTENANCE: '/under-maintenance',
	ADD_NEW_POLICY: '/add-new-policy',
	VERIFY_EMAIL: '/email-verify/:uuid/:email/:token',
	VERIFY_USER: '/user-registration/:uuid/:token',
	PASSWORD_RESET: '/password-reset/:uuid/:token',
	EDIT_POLICY: '/dashboard/policy/:id',
	ADD_NEW_ARTICLE: '/add-new-article',
	EDIT_ARTICLE: '/dashboard/article/:id'
}
export default ROUTES_NAME
